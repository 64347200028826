@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@font-face {
  font-family: 'AvantGarde';
  src: local('AVAMTM'), url(./AVANTN.ttf) format('truetype');
}


html {
	font-family: 'AvantGarde';
	overflow: hidden;
}

body {
	margin: 0;
	font-family: 'AvantGarde';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

@font-face {
    font-family: "Salesforce Sans";
    src: url("https://db.onlinewebfonts.com/t/0fadaa21fcac88ceee0bb8da992c221b.eot");
    src: url("https://db.onlinewebfonts.com/t/0fadaa21fcac88ceee0bb8da992c221b.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/0fadaa21fcac88ceee0bb8da992c221b.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/0fadaa21fcac88ceee0bb8da992c221b.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/0fadaa21fcac88ceee0bb8da992c221b.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/0fadaa21fcac88ceee0bb8da992c221b.svg#Salesforce Sans")format("svg");
}

html {
	font-family: 'Salesforce Sans';
	overflow: hidden;
}

body {
	margin: 0;
	font-family: 'Salesforce Sans';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} 